import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { MarkdownComponent } from '@/components/elements'
import { Heading } from '@/uikit'

import * as styles from '../components/404/styles.module.css'

const Anchor = (props) => {
  return <a {...props} className="font-bold underline transition text-text hover:text-blue transition-color" />
}

const NotFoundPage = () => {
  const {
    markdownRemark: {
      frontmatter: { four_o_four_text: text, four_o_four_animated_text: ani },
    },
  } = useStaticQuery(graphql/* GraphQL */ `
    query fourOFourQuery {
      markdownRemark(fileAbsolutePath: { regex: "/partials/404-page.md/" }) {
        frontmatter {
          four_o_four_text
          four_o_four_animated_text
        }
      }
    }
  `)
  return (
    <div className="text-center md:pl-20 pt-13 md:pt-30 pb-16.5 md:pb-15 px-7.5 max-w-1440 mx-auto w-full">
      <MarkdownComponent
        externalComponents={{
          h1: Heading,
          a: Anchor,
        }}
      >
        {text}
      </MarkdownComponent>
      <div className={styles.focus404}>
        {ani &&
          ani.split('\n').map((word) => {
            return (
              <p className="font-extrabold leading-snug tracking-widest text-9xl">
                {word &&
                  word.split('').map((letter) => {
                    return <span data-shadow={letter}>{letter}</span>
                  })}
              </p>
            )
          })}
      </div>
    </div>
  )
}

export default NotFoundPage
